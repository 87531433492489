var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"locale":_vm.antLocale}},[_c('a-layout',[_c('a-page-header',{scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('locale-changer')]},proxy:true}])},[_c('template',{slot:"tags"})],2),_c('a-layout-content',{staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"logo",staticStyle:{"display":"flex","justify-content":"center","margin-top":"6%"}},[_c('svgWiDLogo')],1),_c('div',{staticClass:"main",staticStyle:{"position":"absolute","top":"28%","width":"32%","left":"34%","background-color":"#fff","padding":"3%"}},[_c('h1',{staticClass:"blackFontStyle",staticStyle:{"font-size":"150%","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t("login"))+" ")]),_c('br'),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('span',{staticClass:"blackFontStyle"},[_vm._v(" "+_vm._s(_vm.$t("username"))+" ")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('username_is_required'),
                    },
                    { validator: _vm.handleUsernameOrEmail } ],
                  validateTrigger: 'blur',
                } ]),expression:"[\n                'username',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('username_is_required'),\n                    },\n                    { validator: handleUsernameOrEmail },\n                  ],\n                  validateTrigger: 'blur',\n                },\n              ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('username')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('span',{staticClass:"blackFontStyle"},[_vm._v(" "+_vm._s(_vm.$t("password"))+" ")]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    { required: true, message: _vm.$t('password_is_required') } ],
                  validateTrigger: 'blur',
                } ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    { required: true, message: $t('password_is_required') },\n                  ],\n                  validateTrigger: 'blur',\n                },\n              ]"}],attrs:{"size":"large","placeholder":_vm.$t('password')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['rememberMe', { valuePropName: 'checked' }]),expression:"['rememberMe', { valuePropName: 'checked' }]"}],staticClass:"blackFontStyle",staticStyle:{"float":"left"}},[_vm._v(_vm._s(_vm.$t("login.remember_me")))])],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.loginBtn,"disabled":_vm.loginBtn}},[_vm._v(_vm._s(_vm.$t("login")))])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }