































































































































import { Component, Vue } from 'vue-property-decorator'

import { appName } from '@/env'
import { readLoginError } from '@/store/main/getters'
import { dispatchLogIn } from '@/store/main/actions'
import Localechanger from './main/other/LocaleProvider.vue'
import svgWiDLogo from '../components/svg_saves/login/WiDLogo.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

@Component({
  components: {
    'locale-changer': Localechanger,
    svgWiDLogo,
  },
})
export default class Login extends Vue {
  //  appName = appName;
  public loginBtn = false
  public form: any = null

  get antLocale() {
    return this.$store.state.main.antLocale || zhCN
  }
  public data() {
    return {
      form: this.$form.createForm(this),
      loginBtn: false,
    }
  }

  public handleSubmit(e) {
    this.loginBtn = true
    e.preventDefault()
    this.form.validateFields((err, values) => {
      if (!err) {
        dispatchLogIn(this.$store, {
          username: values.username,
          password: values.password,
        })

        this.loginBtn = false
      } else {
        this.loginBtn = false
        this.$message.error(
          this.$i18n.tc('login.invalid_credentials')
        )
      }
    })
    this.loginBtn = false
  }

  get loginError() {
    return readLoginError(this.$store)
  }

  public handleUsernameOrEmail(rule, value, callback) {
    const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z]+)+$/
    if (!regex.test(value)) {
      this.$message.error(this.$i18n.tc('username_is_not_valid'))
    }
    callback()
  }
}
